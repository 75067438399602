// @flow
import * as React from 'react'
import { Div, Img, A } from 'glamorous'

import { ResourceHub } from 'views/components'
import videoImage from './img/video-image.png'
import videoImage2x from './img/video-image@2x.png'
import videoImage3x from './img/video-image@3x.png'
import { navy, brandBlue } from 'shared/colors'

const cards = [
	{
		image: (
			<Img
				width="100%"
				src={videoImage}
				srcSet={`${videoImage2x} 2x, ${videoImage3x} 3x`}
			/>
		),
		title: 'Video',
		wording: 'Get started with the smartest calendar in preconstruction.',
		cta: 'Watch the video',
		videoId: 'f4viqrqr0i',
	},
	{
		image: <ResourceHub.DefaultBlueImage />,
		title: 'Blog',
		wording: '3 qualities GCs look for in their subcontractors.',
		cta: 'Read More',
		href:
			'http://blog.buildingconnected.com/3-qualities-GCs-look-for-in-their-subcontractors/',
	},
	{
		title: 'How To',
		wording: (
			<Div>
				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360012780594"
					target="_blank"
				>
					View and edit bid details from your calendar
				</A>
				<br />
				<br />
				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360012906873"
					target="_blank"
				>
					Filter your calendar by assignee and events
				</A>
				<br />
				<br />

				<A
					color={navy}
					href="https://buildingconnected.zendesk.com/hc/en-us/articles/360012761254"
					target="_blank"
				>
					Sync your calendar with GCal, Outlook or iCal
				</A>
			</Div>
		),
		cta: (
			<A
				color={brandBlue}
				href="https://buildingconnected.zendesk.com/hc/en-us?solvvy=true"
				target="_blank"
			>
				Go to Help Center
			</A>
		),
	},
]

const ResourceHubSection = () => (
	<ResourceHub
		title="The resource hub."
		subtitle="All the information you need to make our calendar work for you."
		cards={cards}
	/>
)

export default ResourceHubSection
