// @flow
import * as React from 'react'

import Layout from 'components/layout'
import {
	SectionSpacer,
	ContentWrapper,
	SectionBreak,
	Footer,
	GrayBackground,
} from 'views/components'
import Header from './header'
import OutsmartDeadlines from './outsmart-deadlines'
import ManageTime from './manage-time'
import GetEveryoneInSync from './get-everyone-in-sync'
import ResourceHub from './resource-hub'
import useScreenWidth from 'hooks/use-screen-width'
import OutlookFallsShort from './outlook-falls-short'

const Calendar = () => {
	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	return (
		<Layout
			title="Bid Calendar for Subcontractors | BuildingConnected"
			description="Easily forward bid invites, hit due dates, and keep your team in sync with the smartest calendar in preconstruction."
		>
			<Header screenWidth={screenWidth} />
			<SectionSpacer />
			<ContentWrapper>
				<OutsmartDeadlines screenWidth={screenWidth} />
				<SectionBreak />
				<ManageTime />
				<SectionBreak />
				<GetEveryoneInSync screenWidth={screenWidth} />
				<SectionSpacer />
			</ContentWrapper>
			<GrayBackground>
				<ContentWrapper>
					<SectionSpacer />
					<OutlookFallsShort />
					<SectionSpacer />
				</ContentWrapper>
			</GrayBackground>
			<ContentWrapper>
				<SectionSpacer />
				<ResourceHub />
			</ContentWrapper>
			<SectionSpacer />
			<Footer
				wording="Try Bid Board Pro with your team for free."
				showCreateAccount
			/>
		</Layout>
	)
}

export default Calendar
