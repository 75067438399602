// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { gray60, navy } from 'shared/colors'
import { mobile } from 'shared/media-queries'

const CalendarButton = ({
	active,
	onClick,
	icon,
	title,
	noBorderRight,
}: {
	active?: boolean,
	onClick: () => mixed,
	icon: React.Node,
	title: string,
	noBorderRight?: boolean,
}) => (
	<Div
		padding="5px 0px"
		width="150px"
		onClick={onClick}
		color={active ? 'white' : gray60}
		fontWeight="500"
		alignItems="center"
		justifyContent="center"
		display="flex"
		borderRight={!noBorderRight && `1px solid ${gray60}`}
		backgroundColor={active && navy}
		borderColor={active && navy}
		cursor="pointer"
		css={{
			[mobile]: { width: '90px' },
		}}
	>
		<Div css={{ fill: active ? 'white' : gray60, marginTop: '-2px' }}>
			{icon}
		</Div>
		<Div
			width="10px"
			css={{
				[mobile]: { width: '5px' },
			}}
		/>
		<Div
			css={{
				[mobile]: { fontSize: '16px' },
			}}
		>
			{title}
		</Div>
	</Div>
)

export default CalendarButton
