// @flow
import * as React from 'react'

import { IconAndInfoGrid } from 'views/components'
import CantSync from 'icons/cant-sync.svg'
import TediousManualEntry from 'icons/tedious-manual-entry.svg'
import HardToTrack from 'icons/hard-to-track.svg'
import Sync from 'icons/sync.svg'
import Calendar from 'icons/calendar.svg'
import Phone from 'icons/phone.svg'
import AddUser from 'icons/add-user.svg'
import Scales from 'icons/scales.svg'
import Cards from 'icons/cards.svg'
import MagnifyingGlass from 'icons/magnifying-glass.svg'
import DocumentSearch from 'icons/document-search.svg'
import SeveralCalendars from 'icons/several-calendars.svg'

const outlookBad = [
	{
		icon: <CantSync />,
		title: 'Your inbox and calendar can’t sync',
		text:
			'Easy for dates and details to fall through the cracks, making it hard to hit deadlines.',
	},
	{
		icon: <TediousManualEntry />,
		title: 'Tedious manual entry',
		text:
			'Create folders and manually move the job each time you receive updates.',
	},
	{
		icon: <HardToTrack />,
		title: 'Hard to track',
		text:
			'Not easy to group and prioritize, so it’s easy to miss jobs that need bids.',
	},
]

const calendarGood = [
	{
		icon: <Sync />,
		title: 'Always in sync with your bid board',
		text:
			'Any updates you make on your bid board are reflected on your calendar, and vice versa.',
	},
	{
		icon: <Calendar />,
		title: 'Automatically add due dates',
		text:
			'All the due dates you’re tracking are imported right to your bid board and calendar.',
	},
	{
		icon: <Phone />,
		title: 'Add to your personal calendar',
		text:
			'Syncs with your personal calendar, so you can see your due dates from anywhere.',
	},
	{
		icon: <AddUser />,
		title: 'Assign bids',
		text:
			'Get quick notifications and updates, so you never have two people on the same job.',
	},
	{
		icon: <Scales />,
		title: 'Manage workloads',
		text:
			'See what your team’s working on and who has bandwidth to work on what.',
	},
	{
		icon: <Cards />,
		title: 'Easily organize events',
		text:
			'Customized preconstruction event labels, like bid due dates, job walks, RFIs and follow-up dates.',
	},
	{
		icon: <MagnifyingGlass />,
		title: 'Track your next steps',
		text: 'Know where each project stands in the preconstruction process.',
	},
	{
		icon: <DocumentSearch />,
		title: 'Access details and files',
		text: 'Zoom into bid details and get files right from the calendar.',
	},
	{
		icon: <SeveralCalendars />,
		title: 'Complete visibility',
		text: 'View just your calendar, or your office or your entire company.',
	},
]

const OutlookFallsShort = () => (
	<IconAndInfoGrid
		sections={[
			{
				title: 'See how Outlook falls short in managing bids.',
				content: outlookBad,
			},
			{
				title: 'Bid Calendar: Built for preconstruction.',
				content: calendarGood,
			},
		]}
	/>
)

export default OutlookFallsShort
