// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import { breakpoints, tablet } from 'shared/media-queries'
import { InfoAndImage } from 'views/components'
import desktopImage from './img/desktop.png'
import desktopImage2x from './img/desktop@2x.png'
import desktopImage3x from './img/desktop@3x.png'
import mobileImage from './img/mobile.png'
import mobileImage2x from './img/mobile@2x.png'
import mobileImage3x from './img/mobile@3x.png'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '486px'
	} else if (screenWidth >= breakpoints.laptop) {
		return '400px'
	}
	return undefined
}

const OutsmartDeadlines = ({ screenWidth }: { screenWidth: number }) => (
	<InfoAndImage
		imageOnRight
		image={
			screenWidth >= breakpoints.tablet ? (
				<Img
					src={desktopImage}
					srcSet={`${desktopImage2x} 2x, ${desktopImage3x} 3x`}
					css={{
						[tablet]: { width: '100%' },
					}}
				/>
			) : (
				<Img
					src={mobileImage}
					srcSet={`${mobileImage2x} 2x, ${mobileImage3x} 3x`}
				/>
			)
		}
		wordingWidth={getWordingWidth(screenWidth)}
		subtitle="Outsmart deadlines."
		info="Easily forward bid invites to your BuildingConnected Bid Board. All those bids and details automatically create your calendar for easy tracking."
		bulletItems={[
			'Never miss a due date or job walk',
			'No more having 2 estimators on the same job',
			'Zoom into bid details right from your calendar',
		]}
		centered={screenWidth < breakpoints.tablet}
	/>
)

export default OutsmartDeadlines
