// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { SectionInfo } from 'views/components'
import { cyan, gray80, gray60 } from 'shared/colors'
import CalendarButton from './calendar-button'
import { buttonsAndImages } from './constants'
import { mobile } from 'shared/media-queries'

type State = { activeIndex: number }

class ManageTime extends React.Component<{}, State> {
	state = { activeIndex: 0 }

	render() {
		const { activeIndex } = this.state
		const { image } = buttonsAndImages[activeIndex]

		return (
			<Div display="flex" flexDirection="column" alignItems="center">
				<SectionInfo
					subtitle="Manage your time and your team more efficiently."
					info="Customize your view by week or month and filter to control what you see. That means staying ahead of the game and planning out workloads."
					centered
				/>
				<Div height="20px" />
				<Div
					display="flex"
					alignItems="center"
					css={{
						[mobile]: { flexDirection: 'column' },
					}}
				>
					<Div fontSize="54px" fontWeight="700" color={cyan}>
						2.3x
					</Div>
					<Div width="30px" />
					<Div
						fontSize="18px"
						color={gray80}
						fontWeight="700"
						maxWidth="285px"
						css={{
							[mobile]: { textAlign: 'center' },
						}}
					>
						more bids submitted on time by Bid Board Pro users vs. free users
					</Div>
				</Div>
				<Div height="20px" />
				<Div display="flex" border={`1px solid ${gray60}`} fontSize="18px">
					{buttonsAndImages.map(({ title, icon }, i) => (
						<CalendarButton
							key={title}
							title={title}
							icon={icon}
							onClick={() => this.setState({ activeIndex: i })}
							active={activeIndex === i}
							noBorderRight={i === buttonsAndImages.length - 1}
						/>
					))}
				</Div>
				<Div height="20px" />
				<Div maxHeight="470px">{image}</Div>
			</Div>
		)
	}
}

export default ManageTime
