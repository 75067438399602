// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { Hero } from 'views/components'
import H1 from 'components/h1'
import HeaderBackgroundImage from './background-image'
import { breakpoints, tablet, mobile } from 'shared/media-queries'

const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '590px'
	} else if (screenWidth >= breakpoints.laptop) {
		return '548px'
	} else if (screenWidth >= breakpoints.tablet) {
		return '515px'
	}
	return '370px'
}

const heading = (
	<H1
		additionalCss={{
			[tablet]: { fontSize: '51px' },
			[mobile]: { fontSize: '32px' },
		}}
	>
		The smartest calendar in preconstruction.
	</H1>
)

const subHeading = (
	<Div
		fontSize="24px"
		lineHeight="1.33"
		css={{
			[mobile]: { fontSize: '18px' },
		}}
	>
		Hit due dates, manage workloads and keep your team in sync with one
		calendar.
	</Div>
)

const Header = ({ screenWidth }: { screenWidth: number }) => (
	<Hero
		backgroundImage={<HeaderBackgroundImage screenWidth={screenWidth} />}
		heading={heading}
		subHeading={subHeading}
		wordingWidth={getWordingWidth(screenWidth)}
		videoId="43v0xa8zwd"
	/>
)

export default Header
