// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import { InfoAndImage } from 'views/components'
import image from './img/image.png'
import image2x from './img/image@2x.png'
import image3x from './img/image@3x.png'
import { breakpoints, tablet } from 'shared/media-queries'

const GetEveryoneInSync = ({ screenWidth }: { screenWidth: number }) => (
	<InfoAndImage
		imageOnRight
		image={
			<Img
				src={image}
				srcSet={`${image2x} 2x, ${image3x} 3x`}
				css={{
					[tablet]: { width: '100%', paddingTop: '10px' },
				}}
			/>
		}
		subtitle="Get everyone in sync."
		info="Keep everyone in the loop with one shared team calendar, synced to all your devices."
		wordingWidth={screenWidth < 1440 ? undefined : '450px'}
		centered={screenWidth < breakpoints.laptop}
	/>
)

export default GetEveryoneInSync
