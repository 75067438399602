// @flow
import * as React from 'react'
import { Img } from 'glamorous'

import MonthIcon from './img/month-icon.svg'
import WeekIcon from './img/week-icon.svg'
import AgendaIcon from './img/agenda-icon.svg'
import month from './img/month.png'
import month2x from './img/month@2x.png'
import month3x from './img/month@3x.png'
import week from './img/week.png'
import week2x from './img/week@2x.png'
import week3x from './img/week@3x.png'
import agenda from './img/agenda.png'
import agenda2x from './img/agenda@2x.png'
import agenda3x from './img/agenda@3x.png'

export const buttonsAndImages = [
	{
		title: 'Month',
		icon: <MonthIcon display="block" />,
		image: (
			<Img
				maxWidth="100%"
				src={month}
				srcSet={`${month2x} 2x, ${month3x} 3x`}
			/>
		),
	},
	{
		title: 'Week',
		icon: <WeekIcon display="block" />,
		image: (
			<Img maxWidth="100%" src={week} srcSet={`${week2x} 2x, ${week3x} 3x`} />
		),
	},
	{
		title: 'Agenda',
		icon: <AgendaIcon display="block" />,
		image: (
			<Img
				maxWidth="100%"
				src={agenda}
				srcSet={`${agenda2x} 2x, ${agenda3x} 3x`}
			/>
		),
	},
]
